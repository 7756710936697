import React from "react";
import "./MarineParts.css"; // Importing CSS for styling

const MarineParts = () => {
  return (
    <>
      <div className="marine-parts-container">
        <h2 className="marine-parts-title">Our Popular Product</h2>
        <div className="cards-container">
          <div className="card">
            <img
              src="https://neuronmarine.com/wp-content/uploads/2018/08/Engine-Neuron-Marine-Services-Tading.jpg"
              alt="Marine Part 2"
              className="card-image"
            />
          </div>
          <div className="card">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbPi9Xm4wrqDvm1wpYi19YbSG-zHL7LV7Viw&s"
              alt="Marine Part 2"
              className="card-image"
            />
          </div>
          <div className="card">
            <img
              src="https://5.imimg.com/data5/SELLER/Default/2023/6/320552550/SX/GC/NL/36966172/ship-spare-parts-suppliers-in-mumbai-500x500.jpg"
              alt="Marine Part 2"
              className="card-image"
            />
          </div>
          <div className="card">
            <img
              src="https://shservicebd.com/wp-content/uploads/2023/04/inflatable-boat-price-in-bangladesh-247x296.jpg"
              alt="Marine Part 2"
              className="card-image"
            />
          </div>
          <div className="card">
            <img
              src="https://marinesparesbd.com/cdn/shop/collections/alfa-laval-lopx-713-centrifuge-oil-separator--purifier-fully-refurbished_639_580x.jpg?v=1473429779"
              alt="Marine Part 2"
              className="card-image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarineParts;
