import React from "react";
import "../Slider/Slider.css";
import Navbar from "../Navbar/Navbar";

const Slider = () => {
  return (
    // <div className="slider">
    //   <img src="./images/main.jpg" alt="/"></img>
    // </div>
    <>
      <Navbar />
      <div
        className="snMarine container-fluid  p-0 pb-5"
        style={{ marginTop: -100 }}
      >
        <div className="owl-carousel header-carousel position-relative mb-5">
          <div className="owl-carousel-item position-relative">
            <img
              className="img-fluid"
              src="https://static.vecteezy.com/system/resources/previews/030/601/905/non_2x/import-export-container-ship-on-the-sea-ai-generative-free-photo.jpg"
              alt=""
            />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(6, 3, 21, .5)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    {/* <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                      Transport &amp; Logistics Solution
                    </h5> */}
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Welcome To
                      <span className="text-primary"> Sn Marine</span>{" "}
                      Enterprise
                    </h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2">
                      We care for every single inquiry,Because we are{" "}
                      <span className="text-primary"> Ship Chandler</span>{" "}
                    </p>
                    <a
                      href=""
                      className=" btn btn-primary snbtn2  py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      info@snmarine.net
                    </a>
                    <a
                      href=""
                      className="btn btn-secondary snbtn py-md-3 px-md-5 animated slideInRight"
                    >
                      +8801711-705304
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Carousel End */}
    </>
  );
};

export default Slider;
