// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faPhone,
//   faEnvelope,
//   faMapMarkerAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import "./../Footer/Footer.css";
// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="footer-content">
//         <ul className="footer-links">
//           <li>
//             <a href="/about">About Us</a>
//           </li>
//           <li>
//             <a href="/privacy">Privacy Policy</a>
//           </li>
//           <li>
//             <a href="/contact">Contact</a>
//           </li>
//         </ul>

//         {/* Address, Phone, and Email Section */}
//         <div className="footer-contact">
//           <p>
//             <FontAwesomeIcon icon={faMapMarkerAlt} /> 123 Main Street, Your City
//           </p>
//           <p>
//             <FontAwesomeIcon icon={faPhone} /> +1 234 567 890
//           </p>
//           <p>
//             <FontAwesomeIcon icon={faEnvelope} /> contact@yourcompany.com
//           </p>
//         </div>

//         {/* Footer Links */}

//         <p>
//           &copy; {new Date().getFullYear()} Your Company Name. All rights
//           reserved.
//         </p>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ marginTop: "6rem" }}
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6" style={{ textAlign: "left" }}>
            <h4 className="text-light mb-4">Address</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3" />
              871 Agrabad Access Road Boropol Halishahar, Chittagong, Bangladesh
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3" />
              +88 01711 705304
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3" />
              +88 01915 156353
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3" />
              Info@snmarine.net
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3" />
              Snmarineasr@gmail.com
            </p>

            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube" />
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Services</h4>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              Husbandry Services
            </a>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              Ship Repairs
            </a>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              LSA & FFA Service
            </a>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              Navigational Repair
            </a>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              Engineering Works
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Quick Links</h4>
            <a
              className="btn btn-link"
              href="/about"
              style={{ marginLeft: 66 }}
            >
              About Us
            </a>
            <a
              className="btn btn-link"
              href="/contactus"
              style={{ marginLeft: 66 }}
            >
              Contact Us
            </a>
            <a
              className="btn btn-link"
              href="/services"
              style={{ marginLeft: 66 }}
            >
              Ship Services
            </a>
            <a className="btn btn-link" href="" style={{ marginLeft: 66 }}>
              Terms &amp; Condition
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a className="border-bottom" href="#">
                Snmarine.net
              </a>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              Designed By <a className="border-bottom">Soft Pulse Technology</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
